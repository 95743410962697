import React from 'react';
import type { FC } from 'react';
import { Backdrop, Box, Card, CardContent, Container, Divider, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import Logo from 'components/Logo';
import Form from 'components/LoginForm/Form';
import { Theme } from 'theme';
import { useSelector } from 'store/hooks';
import { getModalProps } from 'slices/modal';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

const LoginForm: FC = (): JSX.Element => {
  const modalProps = useSelector(state => getModalProps(state));
  const { backdrop } = useStyles();

  return (
    <Backdrop open className={backdrop} invisible={!modalProps?.withBackdrop}>
      <Container maxWidth="sm">
        {!modalProps?.withBackdrop && (
          <Box mb={4} display="flex" justifyContent="center">
            <Logo height={64} />
          </Box>
        )}
        <Card>
          <Box minHeight={400} clone display="flex" flexDirection="column">
            <CardContent>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={3}>
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    <FormattedMessage id="auth.signIn" />
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <FormattedMessage id="auth.loginFormDesc" />
                  </Typography>
                </div>
              </Box>
              <Box flexGrow={1} mt={3}>
                <Form withBackdrop={modalProps?.withBackdrop} />
              </Box>
              <Box my={3}>
                <Divider />
              </Box>
              <Link component={RouterLink} to="/reset-password" variant="body2" color="textSecondary">
                <FormattedMessage id="auth.forgotPasswordLink" />
              </Link>
            </CardContent>
          </Box>
        </Card>
      </Container>
    </Backdrop>
  );
};

export default LoginForm;
